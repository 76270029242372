import type {
  AnchorHTMLAttributes,
  Dispatch,
  HTMLAttributes,
  InputHTMLAttributes,
  SetStateAction,
} from 'react';
import { useMemo } from 'react';
import { propOrDefault, type KeyOf } from '../../common/util/object-utils';
import type { IsTypeAny } from '../../common/util/type-util-IsAny';
import { useAlwaysLatest, type Memoized, type RemoveMemoizedTag } from './react-memo-util';

export const center = { textAlign: 'center' } as const;

/** preserve spaces */
export const preserve = { whiteSpace: 'pre-wrap' } as const;
export const prenowrap = { whiteSpace: 'pre' } as const;

export const flex = { display: 'flex' } as const;
export const flexMid = { display: 'flex', alignItems: 'center' } as const;
export const flexColumn = { display: 'flex', flexDirection: 'column' } as const;
export const flexGrow = { flexGrow: 1 } as const;
export const flexWrap = { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' } as const;
export const flexCentered = { display: 'flex', alignItems: 'center', justifyContent: 'center' } as const;

export type ButtonAttrs = HTMLAttributes<HTMLButtonElement>;
export type DivAttrs = HTMLAttributes<HTMLDivElement>;
export type SpanAttrs = HTMLAttributes<HTMLSpanElement>;
export type HyperlinkAttrs = AnchorHTMLAttributes<HTMLAnchorElement>;
export type InputAttrs = InputHTMLAttributes<HTMLInputElement>;
export type TdAttrs = HTMLAttributes<HTMLTableCellElement>;
export type TrAttrs = HTMLAttributes<HTMLTableRowElement>;

// —————————————————————————————————————————————————————————————————————————— //

/** Same as `useState` return type, but without `declaration- */
export type UsedState<T> = [T, SetState<T>];
export type UsedStateOrig<T> = readonly [Memoized<T>, Memoized<SetState<T>>];

export type SetState<T> = Dispatch<SetStateAction<RemoveMemoizedTag<T>>>; // copied from useState in node_modules/@types/react/index.d.ts

export type ValueSetStateIsFor<T> = T extends SetState<infer U> ? U : never;

/**
 * Return value of {@link getset} or {@link useGetset}.
 */
export type GetSet<T> = { val: T; set: SetState<T> };

export type GetSetResult<T extends UsedState<any> | UsedStateOrig<any>> = { val: T[0]; set: T[1] };

/**
 * Unlike React's [official set state implementation](https://react.dev/reference/react/useState#setstate),
 * this is simplified so that `nextState` (first parameter to `set` state) may never be a `(prevVal) => newVal` function.
 *
 * Example usage is `BDatePicker`, which only relies on the simpler implementation.
 * Therefore functions invoking `BDatePicker` can override `set` more easily.
 *
 * You can always pass a normal {@link GetSet} to a component that requires {@link GetSetSimplified}.
 *
 * However, {@link GetSetSimplified} cannot be passed into a component that requires full {@link GetSet}
 * because it might invoke `set` with a function as the `nextState` parameter rather than a simple value.
 */
export type GetSetSimplified<T> = { val: T; set: (nextState: T) => void };

export type SetStateSimple<T> = (nextState: T) => void;

export type GetSetReadonly<T> = { val: T; set?: null };

// —————————————————————————————————————————————————————————————————————————— //

/**
 * Simply converts `[value, setValue]` array tuple
 * into a 'tuple' object `{ val: value, set: setValue }`
 * so you can more conveniently pass around one prop instead of two.
 */
export function getset<Tuple extends UsedState<any> | UsedStateOrig<any>>(
  stateReturn: Tuple,
): GetSetResult<Tuple> {
  return {
    val: stateReturn[0],
    set: stateReturn[1],
  };
}

/**
 * Simple memoized version of {@link getset}.
 *
 * Even if these are equal between rerenders:
 * - `useState()[0] === useState()[0]`
 * - `useState()[1] === useState()[1]`
 *
 * nevertheless these will not be equal:
 * - `useState() !== useState()`
 *
 * but conveniently `useGetset(useState()) === useGetset(useState())`. That gives you are more useful 'tuple' object to pass around. :)
 *
 * (of course `getset(useState()) !== getset(useState())` since it's not memoized)
 */
export function useGetset<T>(stateReturn: UsedStateOrig<T>): Memoized<GetSetResult<UsedStateOrig<T>>> {
  return useMemo(
    () => ({
      val: stateReturn[0],
      set: stateReturn[1],
    }),
    stateReturn, // eslint-disable-line react-hooks/exhaustive-deps
  );
}

type MemoizedIfOuterMemoized<T, Outer> = Outer extends Memoized<unknown> ? Memoized<T> : T;

type SubSetState<
  StateOuter extends { val: any; set?: any },
  K extends keyof StateOuter['val'],
  IfUndefined extends StateOuter['val'][K],
> =
  IsTypeAny<StateOuter['set']> extends true
    ? SetState<any>
    : StateOuter['set'] extends null | undefined
      ? null
      : MemoizedIfOuterMemoized<SetState<UndefinedSwitch<StateOuter['val'][K], IfUndefined>>, StateOuter>;

type SubUseState<
  StateOuter extends { val: any },
  K extends keyof StateOuter['val'],
  IfUndefined extends StateOuter['val'][K],
> = [UndefinedSwitch<StateOuter['val'][K], IfUndefined>, SubSetState<StateOuter, K, IfUndefined>];

type UndefinedSwitch<Normal, IfUndefined> = Normal extends undefined ? IfUndefined : Normal;

/**
 * Shorthand for {@link getset} + {@link subUseState}. A readable & writable view into a single property of another state.
 *
 * `subState` is not a hook so is not subject to the rules of hooks as `useState` would be.
 * This means you can do really cool things like calling `subState` with an array index as {@link key} inside of a React `.map` loop!
 * (example assumes `stateOuter` is an array state from {@link getset})
 *
 * For memoized version see {@link useSubState}. (is a hook)
 */
export function subState<
  StateOuter extends { val: any },
  K extends KeyOf<StateOuter['val']>,
  IfUndefined extends StateOuter['val'][K],
>(
  stateOuter: StateOuter,
  key: K,
  ifUndefined?: IfUndefined,
): {
  val: SubUseState<StateOuter, K, IfUndefined>[0];
  set: SubUseState<StateOuter, K, IfUndefined>[1];
} {
  return getset(subUseState(stateOuter, key, ifUndefined));
}

/**
 * Is a hook version of {@link subState}.
 *
 * Shorthand for {@link useGetset} (memoize the 'tuple' object) + {@link useSubUseState} (memoize the `set` portion).
 *
 * If memoizing of the 'tuple' object is not needed, you can just use {@link useSubUseState} by itself.
 */
export function useSubState<
  StateOuter extends { val: any },
  K extends KeyOf<StateOuter['val']>,
  IfUndefined extends StateOuter['val'][K] | Memoized<StateOuter['val'][K]>,
>(
  stateOuter: StateOuter,
  key: K,
  ifUndefined?: IfUndefined,
): {
  val: SubUseState<StateOuter, K, IfUndefined>[0];
  set: SubUseState<StateOuter, K, IfUndefined>[1];
} {
  return useGetset(useSubUseState(stateOuter, key, ifUndefined));
}

/**
 * Returns a readable & writable view into a single property of another state.
 *
 * Same as {@link subState}, but returns a normal array tuple just like `useState` would.
 *
 * `subUseState` is not a hook so is not subject to the rules of hooks as `useState` would be.
 * This means you can do really cool things like calling `subUseState` with an array index as {@link key} inside of a React `.map` loop!
 * (example assumes `stateOuter` is an array state from {@link getset})
 *
 * For memoized version see {@link useSubUseState}. (is a hook)
 */
export function subUseState<
  StateOuter extends { val: any },
  K extends KeyOf<StateOuter['val']>,
  IfUndefined extends StateOuter['val'][K],
>(stateOuter: StateOuter, key: K, ifUndefined?: IfUndefined): SubUseState<StateOuter, K, IfUndefined> {
  return [
    propOrDefault(stateOuter.val, key, ifUndefined),
    stateOuter.set ? subSetState(stateOuter.set, key, ifUndefined) : null,
  ];
}

/**
 * Is a hook version of {@link subUseState}. Properly memoize the 'set' portion so you can use in later on memoizing if needed.
 *
 * Equality between rerenders:
 * - `useState()[1] === useState()[1]`
 * - `subUseState(...)[1] !== subUseState(...)[1]`
 * - `subState(...).set !== subState(...).set`
 * - `useSubUseState(...)[1] === useSubUseState(...)[1]`
 * - `useSubState(...).set !== useSubState(...).set`
 */
export function useSubUseState<
  StateOuter extends { val: any },
  K extends KeyOf<StateOuter['val']>,
  IfUndefined extends StateOuter['val'][K],
>(stateOuter: StateOuter, key: K, ifUndefined?: IfUndefined): SubUseState<StateOuter, K, IfUndefined> {
  const latestFallbackRef = useAlwaysLatest(ifUndefined);
  const set = useMemo(
    () => (stateOuter.set ? subSetState(stateOuter.set, key, () => latestFallbackRef.current) : null),
    [stateOuter.set, key, latestFallbackRef],
  );
  return [propOrDefault(stateOuter.val, key, ifUndefined), set];
}

/**
 * write-only half of {@link subUseState}
 */
export function subSetState<T extends object, K extends KeyOf<T>, IfUndefined extends T[K]>(
  setStateOuter: SetState<T>,
  key: K,
  ifUndefined?: IfUndefined | (() => IfUndefined),
): SetState<UndefinedSwitch<T[K], IfUndefined>> {
  return (value) => {
    return setStateOuter((prev) => {
      const newValue =
        typeof value === 'function'
          ? (value as any)(
              propOrDefault(prev, key, typeof ifUndefined === 'function' ? ifUndefined() : ifUndefined),
            )
          : value;
      if (newValue === prev[key]) return prev;
      return merge(prev, { [key]: newValue });
    });
  };
}

function merge<A, B>(oldO: A, assignments: B): A & B {
  return Array.isArray(oldO) ? Object.assign([], { ...oldO, ...assignments }) : { ...oldO, ...assignments };
}

export function joinClasses(classArray: (string | null | undefined | false)[]) {
  return classArray.filter(Boolean).join(' ');
}
